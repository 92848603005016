<template>
  <j-dialog
    :value="$dialog.status('wiki-help')"
    max-width="600px"
    title="Quick Formatting Guide"
    @cancel="handleClose"
  >
    <j-card-text>
      <j-markdown :source="helpText" class="wiki-help-text" />
    </j-card-text>
  </j-dialog>
</template>

<script>
export default {
  name: "WikiHelp",
  computed: {
    helpText() {
      return `
This wiki uses **Markdown** to provide basic formatting to its pages. For all the details, you can visit the **[Markdown Reference](https://www.markdownguide.org/basic-syntax)** website, but here are some quick examples to get started.

---

### Inline Formatting

| To get this... | ... enter this |
| -------------- | ------------- |
| Need some **bold** text | \`Need some **bold** text\` |
| I like _italic_ text | \`I like _italic_ text\` |
| How about [a link](http://a.real.link/)? | \`How about [a link](http://a.real.link/)?\` |
| Can we mix **[things up](http://another.real.link/)**? | \`Can we mix **[things up](http://another.real.link/)**?\` |

---

### Headings and Paragraphs

To make paragraphs, insert a blank line between them.

\`\`\`
This is the first paragraph.

This is the second.
\`\`\`

---

To make a heading, put one or more \`#\` to its left.

---

\`# Top Level Header\`

# Top Level Header

---

\`### Third Level Header\`

### Third Level Header

---

### Lists

You can make bullet lists by adding a hyphen to the left of your list.

\`\`\`
- First thing
- Second thing
- There is no thing three
\`\`\`

- First thing
- Second thing
- There is no thing three

---

You can make a numbered list by adding numbers to the left of your list.

\`\`\`
1. The top priority
1. Wait a minute
1. Numbers are automatic!
\`\`\`

1. The top priority
1. Wait a minute
1. Numbers are automatic!

---

You can nest lists by adding four spaces before the marker. Both types of lists can be used together.

\`\`\`
1. We start
    - Much fanfare
    - Excitement!
1. The middle bit
1. Celebrate the end
    - Cake
    - Ice cream
\`\`\`

1. We start
    - Much fanfare
    - Excitement!
1. The middle bit
1. Celebrate the end!
    - Cake
    - Ice cream

---

### Images, attachments, and other advanced features

See the **[How To Wiki](/wiki/staff/wiki-help)** page on this wiki.
`
    },
  },
  methods: {
    handleClose() {
      this.$dialog.close('wiki-help')
    }
  }
}
</script>

<style lang="scss">
.wiki-help-text {
  table {
    width: 100%;
  }
  td {
    width: 50%;
  }
  h1 {
    @extend %j-heading-1;
  }
  h2 {
    @extend %j-heading-2;
  }
  h3 {
    @extend %j-heading-3;
  }
  h4 {
    @extend %j-heading-3;
  }
  hr {
    margin: 16px 0;
  }
  @extend %j-body;
}
</style>
