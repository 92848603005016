<template>

  <!-- Wiki Page View -->
  <j-page
    :title="pageTitle"
    :loading="loading"
    class="wiki-page-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Form Actions -->
      <j-actions
        v-if="editMode"
        :record="wikiPage"
        @cancel="handleCancel"
        @save="handleSave"
      />

      <!-- Edit Button -->
      <j-button
        v-else-if="allowEdits"
        type="icon"
        icon="edit"
        help="Edit this page"
        @action="handleEdit"
      />

    </template>

    <!-- Wiki Form -->
    <v-col
      cols="12"
      :sm="editMode ? 12 : 9"
    >
      <!-- Not Found -->
      <j-alert
        v-if="!loading && wikiPage.isNew()"
        type="warning"
        message="This page could not be found."
      />

      <!-- Wiki Form -->
      <WikiForm v-if="editMode" />

      <!-- Wiki Page -->
      <WikiPage v-else />

    </v-col>

    <!-- Wiki Navigation -->
    <v-col
      v-if="!editMode"
      cols="12"
      sm="3"
    >
      <j-card class="wiki-navigation">
        <WikiNavigation
          :node="wikiPage.navigation"
          :current-path="wikiPage.id"
          class="pa-2"
        />
      </j-card>
      <!-- Image Manager -->
      <!-- <WikiAttachmentManager /> -->
    </v-col>
  </j-page>
</template>

<script>

// Components
// import WikiAttachmentManager from '@/components/wiki/wiki-attachment-manager'
import WikiForm from '@/components/panels/wiki/WikiForm'
import WikiNavigation from '@/components/panels/wiki/WikiNavigation'
import WikiPage from '@/components/panels/wiki/WikiPage'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('wiki')

export default {
  name: 'WikiView',
  // -------------
  // Components ==
  // -------------
  components: {
    // WikiAttachmentManager,
    WikiNavigation,
    WikiForm,
    WikiPage
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      vm.handleLoad(to)
    })
  },
  beforeRouteUpdate(to, _from, next) {
    this.handleLoad(to)
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.wikiPage.title || ''
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'editMode',
      'wikiPage'
    ]),
    ...mapGetters([
      'allowEdits',
      'loading'
    ]),
    pageTitle () {
      return !this.loading && this.wikiPage.isNew() ? 'Not Found' : this.wikiPage.$.title
    },
    mobile() {
      return ['xs', 'sm', 'md'].includes(this.$mq)
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'loadPage',
      'viewPage',
      'editPage',
      'resetPage',
      'savePage'
    ]),
    pageParams(route) {
      const wiki = route.params.wiki
      const path = route.path.replace(`/wiki/${wiki}`, '') || 'index'
      return {
        wiki,
        path
      }
    },
    async handleLoad(router) {
      const pageParams = this.pageParams(router)
      await this.loadPage(pageParams)
        // TODO: Redirects
        // .then(() => { this.checkForRedirect() })
        .catch((e) => {
          console.error('wiki error', e)
        })
    },
    handleCancel() {
      if (this.wikiPage.isNew()) {
        this.$router.back()
      } else {
        this.resetPage()
        this.viewPage()
      }
    },
    handleSave() {
      this.savePage()
    },
    handleEdit() {
      this.editPage()
    }
    // checkForRedirect(wiki) {
    //   const redirect = this.wikiPage.get('redirect')
    //   if (redirect) {
    //     const redirectPath = `/wiki/${wiki}${redirect}`
    //     this.$router.push(redirectPath)
    //   } else {
    //     // Redirect to wiki root with a slash to keep
    //     // links on the root page from breaking
    //     const needsSlash = this.$route.name == 'wiki-index' && !this.$route.path.endsWith('/')
    //     if (needsSlash) {
    //       this.$router.push(`/wiki/${wiki}/index`)
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss">

.wiki-navigation {
  .wiki-navigation-node {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: $text-color;
  }
  .wiki-root {
    @extend %j-heading-2;
    color: $text-color;
  }
  .wiki-navigation-node {
    @extend %j-body;
    margin-bottom: 0;
    color: $text-color;
  }
  .router-link-exact-active {
    font-weight: bold;
    color: $primary-color;
  }
  p {
    @extend %j-body;
    margin-bottom: 0.25rem;
    &:hover {
      color: $primary-color;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0.75rem;
    margin-bottom: 0;
  }
}

.wiki-editor {
  border-top: none;
}
</style>
