<template>
  <div class="wiki-page" @click.capture="handleClick">
    <!-- Navigation Notice -->
    <p v-if="mobile" class="text-center font-weight-bold">
      Wiki Navigation is available below this page
    </p>

    <!-- Page Content   -->
    <j-markdown
      :source="wikiPage.get('body')"
      class="wiki-content"
      :breaks="false"
    />

    <hr v-if="wikiPage.last_update" />

    <!-- Last Update Footer -->
    <p v-if="wikiPage.last_update" class="update-notes">
      Last update: {{ wikiPage.last_update | longDate }} by
      {{ wikiPage.author_name }}
    </p>
  </div>
</template>

<script>
// Vuex
import { createNamespacedHelpers } from "vuex";
// import { log } from 'util'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers("wiki");

export default {
  name: "WikiPage",
  computed: {
    ...mapState(["wikiPage"]),
    ...mapGetters(["wikiBinaries", "wikiImages"]),
    mobile() {
      return ["xs", "sm", "md"].includes(this.$mq);
    },
  },
  methods: {
    ...mapActions(["editPage"]),
    imageParser(match, alt, source) {
      let imageTag = match;
      // Find a matching image
      let image = this.wikiPage.images.find({ filename: source });
      // Change imageTag if matched
      if (image) {
        // Parse out any alignment stuff
        let alignmentMatches = alt.match(/^\s*([<>|-]?)\s*(.*)/);
        // Update alt with removed alignment characters
        alt = alignmentMatches[2];
        // Determine alignment class
        let alignmentClass = "img-fluid";
        switch (alignmentMatches[1]) {
          case "<":
            alignmentClass = "img-left float-left";
            break;
          case ">":
            alignmentClass = "img-right float-right";
            break;
          case "|":
            alignmentClass = "img-center mx-auto d-block";
            break;
        }
        // Build image tag
        imageTag = `<img class="${alignmentClass} wiki-image" src="${image.src.display}" alt="${alt}"/>`;
      }
      return imageTag;
    },
    linkParser(match, text, href) {
      // Find a matching binary
      let binary = this.wikiPage.binaries.find({ filename: href });
      let binaryTag = "";
      // Matched
      if (binary) {
        // Build mardown tag
        binaryTag = `[${text}](${binary.src})`;
      } else {
        // Ignore unmatched
        binaryTag = match;
      }
      return binaryTag;
    },
    wikiPreRender(markdown) {
      // Process images
      const imageRegex = /(?:!\[(.*?)\]\((.*?)\))/g;
      markdown = markdown.replace(imageRegex, this.imageParser);
      // Process binaries
      const linkRegex = /(?:\[(.*?)\]\((.*?)\))/g;
      markdown = markdown.replace(linkRegex, this.linkParser);
      return markdown;
    },
    handleClick($event) {
      // Extract the target from the event
      let { target } = $event;
      // Climb up the tree to find the anchor link
      while (target && target.tagName !== "A") target = target.parentNode;
      // Handle only internal links inside the preview component
      if (target && target.matches(".wiki-content a") && target.href) {
        // Sanity checks - https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey,
          button,
          defaultPrevented,
        } = $event;
        // Honor <event>.prevent
        if (defaultPrevented) return;
        // Control keys bypass behavior
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // Right clicks bypass behavior
        if (button !== undefined && button !== 0) return;
        // `target="_blank"` bypasses behavior
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute("target");
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        const href = target.getAttribute("href");
        const to = new URL(target.href).pathname;
        // Don't handle mailtos
        if (/^mailto:/.test(href)) return;
        // All external URLs open in a new window
        if (/^https?:\/\//.test(href)) {
          $event.preventDefault();
          window.open(href, "_blank");
          // Only continue if we're going to a different page
        } else if (window.location.pathname !== to && $event.preventDefault) {
          // Cancel original click
          $event.preventDefault();
          // Is it a binary or a page?
          if (to.startsWith("/wiki_source/")) {
            // Binary - use window to load
            window.location.href = to;
          } else {
            // Page - send link to router
            this.$router.push(to);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.wiki-content {
  hr {
    margin: 1.5rem 0;
    border: 1px dotted $primary-color;
  }
  h1 {
    @extend %j-heading-1;
    margin-bottom: 0.5em;
  }
  h2 {
    @extend %j-heading-2;
    margin-bottom: 0.5em;
  }
  h3 {
    @extend %j-heading-3;
    margin-bottom: 0.5em;
  }
  p, ul, ol {
    @extend %j-body;
    margin-bottom: 0.5em;
  }
  a {
    text-decoration: underline;
  }
  table {
    width: 100%;
    border: 1px solid $secondary-color;
    @extend %j-body;
    th {
      text-align: left;
      padding: 4px;
      border-bottom: 1px solid $secondary-color;
      @extend %j-label;
    }
    td {
      padding: 4px;
      border-bottom: 1px solid $secondary-color;
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.img-left,
.img-right,
.img-center {
  width: 50%;
}
</style>
