<template>
  <!-- Root -->
  <div
    v-if="node.type == 'root'"
    class="wiki-navigation-node wiki-navigation-root"
  >
    <!-- Header / Home Link  -->
    <router-link
      :to="{ name: 'wiki-view', params: [node.children[0].path] }"
      class="wiki-root"
    >
      {{ node.children[0].name == 'index' ? node.name : node.children[0].name }}
    </router-link>

    <j-divider class="my-2" />

    <!-- Search Input -->
    <!--
      <WikiSearch
      class="mb-2"
      @search="search"
      /> 
    -->

    <!-- Contents -->
    <wiki-navigation
      v-for="child in node.children"
      :key="child.path"
      :node="child"
      :current-path="currentPath"
    />
  </div>

  <!-- Directory -->
  <div
    v-else-if="node.type == 'directory'"
    class="wiki-navigation-node wiki-navigation-directory"
  >
    <!-- Directory with index page -->
    <div
      v-if="node.children[0] && node.children[0].type == 'index'"
      class="wiki-navigation-node wiki-navigation-index"
    >
      <router-link
        :to="{ name: 'wiki-view', params: [node.children[0].path] }"
        tag="p"
      >
        <j-icon
          v-if="onPath || isOpen"
          :icon="['fas', 'folder-open']"
          class="folder-icon"
        />
        <j-icon
          v-else
          :icon="['fas', 'folder']"
          class="folder-icon mr-1"
        />
        {{ node.children[0].name == 'index' ? node.name : node.children[0].name }}
      </router-link>
    </div>

    <!-- Directory without index page -->
    <a
      v-else-if="node.children.length"
      href="#"
      class="wiki-navigation-node wiki-navigation-no-index"
      @click.prevent="toggle"
    >
      <j-icon
        v-if="onPath || isOpen"
        :icon="['fas', 'folder-open']"
        class="folder-icon mr-1"
      />
      <j-icon
        v-else
        :icon="['fas', 'folder']"
        class="mr-1"
      />
      {{ node.name }}
    </a>

    <!-- Directory Contents -->
    <ul v-show="onPath || isOpen">
      <li
        v-for="child in node.children"
        :key="child.name"
      >
        <wiki-navigation
          :node="child"
          :current-path="currentPath"
        />
      </li>
    </ul>
  </div>

  <!-- Page -->
  <div
    v-else-if="node.type == 'page'"
    class="wiki-navigation-node wiki-navigation-page"
  >
    <router-link
      :to="{ name: 'wiki-view', params: [node.path] }"
      tag="p"
    >
      <j-icon
        :icon="['fas', 'file-alt']"
        class="page-icon mr-1"
      />
      {{ node.name }}
    </router-link>
  </div>
</template>

<script>
// import WikiSearch from '@/components/wiki/wiki-search'

export default {
  name: 'WikiNavigation',
  components: {
    // WikiSearch
  },
  props: {
    node: {
      type: [Object, String],
      default: null
    },
    currentPath: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: this.onPath
    }
  },
  computed: {
    onPath() {
      return this.currentPath ? (this.currentPath.startsWith(this.node.path) || `${this.currentPath}/`.startsWith(this.node.path)) : false
    },
    newPageParams() {
      return {
        wiki: this.$route.params.wiki,
        wikiPath: this.node.path.replace(/\/$/, '').split('/')
      }
    }
  },
  methods: {
    search(query) {
      this.$router.push({name: 'wiki search results', query: { q: query }})
    },
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
